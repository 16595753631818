.contenedor-nosotros {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  padding: 100px 10%;
  margin-top: 100px;
  background: #fff;
}

.contenedor-texto-nosotros {
  align-self: center;
}

.titulo-nosotros {
  font-weight: 600;
  font-size: 44px;
  line-height: 56px;
  color: #01295f;
  margin-bottom: 15px;
}

.texto-nosotros {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #515f72;
}

.img-teamwork {
  width: 100%;
  max-width: 550px;
  max-height: 540px;
  justify-self: end;
  align-self: center;
}

/* MEDIAS QUERY'S  */

@media screen and (max-width: 1100px) {
  .contenedor-nosotros {
    padding: 100px 5%;
  }
}
@media screen and (max-width: 820px) {
  .contenedor-nosotros {
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    gap: 50px;
    padding: 100px 10%;
  }
  .titulo-nosotros {
    text-align: center;
  }
  .img-teamwork {
     justify-self: center;
  }
}
@media screen and (max-width: 420px) {
  .contenedor-nosotros {
    padding: 100px 5%;
  }
}
