.titulo-preguntas {
  font-weight: 600;
  font-size: 28px;
  line-height: 56px;
  color: #01295f;
  text-align: center;
}

.linea {
  margin: 0 auto;
  border: 2px solid #01295F;
  opacity: 1;
  width: 209px;
}

.contenedor-preguntas {
  padding: 65px 20%;
}

.pregunta {
  background: #f4f7ff;
  border: 1px solid #f0f0f0;
  height: 95px;
  display: grid;
  grid-template-columns: 42px auto;
  align-items: center;
  cursor: pointer;
}

.pregunta svg {
  justify-self: center;
}

.question-text {
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #01295f;
  overflow: hidden;
}

.pregunta:hover path,
.pregunta:hover .question-text {
  color: #3fd77b;
  stroke: #3fd77b;
  transition: 150ms linear all;
}

.pregunta.activa path,
.pregunta.activa .question-text {
  color: #0f5ef6;
  stroke: #0f5ef6;
  transition: 150ms linear all;
}

.respuesta {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #515f72;
  overflow: hidden;
  max-height: 0;
  transition: all 0.5s cubic-bezier(0,1,0,1);
  padding: 0px 5%;
  margin: 10px 0px;
}

.respuesta.activa {
  transition: all 0.5s cubic-bezier(1,0,1,0);
  height: auto;
  max-height: 9999px;
}

/* MEDIAS QUERY'S  */

@media screen and (max-width:1100px) {
  .contenedor-preguntas {
    padding: 65px 10%;
  }
}

@media screen and (max-width:600px) {
  .contenedor-preguntas {
    padding: 65px 5%;
  }
  .pregunta {
    height: auto;
  }
}
