.contenedor-newsletter {
  background-color: #232ED1;
  color: #FFFFFF;
  text-align: center;
  padding: 60px 15%;

}

.texto-pregunta {
  font-weight: 400;
  font-size: 28px;
  line-height: 40px;
  margin-bottom: 10px;
}

.texto-suscribete {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.contenedor-suscribir {
  margin-top: 25px;
}

.email {
  position: relative;
  min-width: 290px;
  max-height: 450px;
  height: 52px;
  display: inline-block;
  margin-right: 20px;
}

.email-icon {
  position: absolute;
  top: 0;
  left: 0;
  padding: 18px 17.67px 16.5px;
}

.input-email {
  background: #141BBC;
  border-radius: 4px;
  border: none;
  outline: none;
  height: 52px;
  padding-left: 52px;
  padding-right: 17px;
  width: 100%;
  color: white;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}

.input-email::placeholder {
  color: #C3C5D2;
}

.boton-suscribirme {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #232ED1;
  background: #FFFFFF;
  border-radius: 4px;
  border: none;
  height: 52px;
  width: 150px;
  display: inline-block;
}

.contenedor-infoyform {
  background-color: #010D1C;
  display: grid;
  /* grid-template-columns: max-content minmax(320px,max-content); */
  grid-template-columns: max-content minmax(320px, 500px);
  align-items: start;
  justify-content: center;
  padding: 60px 8% 40px;
  gap: 30px;
}

.contendor-info {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-template-rows: repeat(2, auto);
  gap: 45px;
  width: fit-content;
}

.fila1 {
  grid-row: 1 / 2;
  grid-column: 1 / -1;
  height: 64px;
  width: 64px;
  background: #232ED1;
  border-radius: 8px;
  display: grid;
  align-self: end;
  margin-bottom: 10px;
}

.logo-footer {
  align-self: center;
  justify-self: center;
  width: 40px;
  height: 40px;
}

.titulo-seccion {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #232ED1;
  margin-bottom: 30px;
}

.texto-seccion {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
  margin-bottom: 25px;
  display: block;
  text-decoration: none;
  cursor: pointer;
  width: auto;
}

.texto-seccion:hover {
  color: #92E3A9;
}

.form-dudas {
  background-color: #17202C;
  padding: 10% 4% 0% 4%;
  border-radius: 8px;
  height: 520px;
  max-width: 500px;
}

.form-dudas div {
  margin-bottom: 2%;
}

.titulo-form-dudas {
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
  margin-bottom: 40px;
}

.nombre-form {
  position: relative;
}

.nombre-icon {
  position: absolute;
  top: 0;
  left: 0;
  padding: 16.83px 13.33px 17.67px 13.33px;
}

.input-nombre {
  width: 100%;
  background: #293648;
  border-radius: 4px;
  outline: none;
  border: none;
  height: 52px;
  padding-left: 40px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #FFF;
}

.input-nombre::placeholder {
  color: #C3C5D2;
}

.email-form {
  position: relative;
}

.mail-icon {
  position: absolute;
  top: 0;
  left: 0;
  padding: 18.5px 11.67px;
}

.input-mail {
  width: 100%;
  background: #293648;
  border-radius: 4px;
  height: 52px;
  outline: none;
  border: none;
  padding-left: 40px;
  color: #FFF;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}

.input-mail::placeholder {
  color: #C3C5D2;
}

.text-box-form {
  background: #293648;
  border-radius: 4px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #FFF;
  border: none;
  outline: none;
  min-height: 160px;
  width: 100%;
  padding: 16px 10px;
  resize: none;
}

.text-box-form::placeholder {
  color: #C3C5D2;
}

.alinear-boton {
  text-align: end;
}

.boton-enviar {
  background: linear-gradient(86.42deg, #232ED1 11.64%, #0F5EF6 80.17%);
  box-shadow: 8px 8px 28px rgba(35, 46, 209, 0.16);
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
  border: none;
  width: 188px;
  height: 52px;
}

.boton-enviar:hover {
  background: #232ED1;
}

.background-redes {
  background-color: #010D1C;
  width: 100%;
}

.contenedor-redesyderechos {
  display: grid;
  grid-template-columns: auto auto;
  height: 60px;
  background-color: #010D1C;
  color: #FFF;
  border-top: 1px solid #232ED1;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin: 0% 8%;
}

.derechos {
  align-self: center;
}

.redes {
  display: grid;
  grid-template-columns: repeat(5, auto);
  width: fit-content;
  align-items: center;
  justify-self: end;
  gap: 10px;
}

.iconRedes {
  display: grid;
  justify-items: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: #232ED1;
  border-radius: 8px;
  cursor: pointer;
}

.iconRedes:hover {
  background: #3FD77B;
}

/* MEDIA QUERY'S */

@media screen and (min-width:768px){
  .texto-seccion{
    width: fit-content;
  }
}

@media screen and (max-width:1670px) {
  .contenedor-infoyform {
    grid-template-columns: repeat(1, auto);
    grid-template-rows: repeat(2, auto);
    justify-items: center;
  }

  .form-dudas {
    width: 500px;
  }
}

@media screen and (max-width:940px) {
  .contenedor-redesyderechos {
    grid-template-columns: 1fr;
    grid-template-rows: 60px 60px;
    height: auto;
    align-content: center;
    justify-items: center;
  }

  .redes {
    grid-row: 1 / 2;
    justify-self: center;
  }

  .derechos {
    grid-row: 2 / -1;
  }

  .email {
    margin-bottom: 20px;
  }


}

@media screen and (max-width:880px) {
  .contendor-info {
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-template-rows: repeat(3, auto);
    gap: 45px;
    width: fit-content;
  }

  .contenedor-infoyform {
    padding: 60px 0% 40px;
    justify-content: center;
  }
}

@media screen and (max-width:560px) {
  .contenedor-newsletter {
    text-align: center;
    padding: 60px 5%;
    width: 100%;
  }

  .email {
    margin-right: 20px;
    margin-bottom: 20px;
  }

  .contendor-info {
    display: grid;
    grid-template-columns: repeat(1, minmax(310px, 480px));
    grid-template-rows: repeat(5, auto);
    gap: 45px;
    width: fit-content;
    text-align: center;
  }

  .fila1 {
    align-self: center;
    justify-self: center;
  }

  .contenedor-infoyform {
    padding: 60px 0% 40px;
    justify-items: normal;
  }

  .form-dudas {
    width: 100%;
    justify-self: start;
    align-self: start;
  }

  .contenedor-redesyderechos {
    grid-template-rows: auto 60px;
    padding-top: 10px;
  }

  .redes {
    grid-template-columns: repeat(4);
    grid-template-rows: repeat(2, auto);
    justify-items: center;
  }

  .redes h6 {
    grid-column: 1 / -1;
  }

  .derechos {
    text-align: center;
  }
}