
p {
  margin: 0;
}

.contenerdorPrecios {
  background: linear-gradient(to bottom, #F4F7FF 0%, #F4F7FF 60%, #fff 60%, #fff 100%);
  padding: 11% 6% 0%;
}

.header {
  text-align: center;
  padding: 0% 13%;
  margin-bottom: 40px;
  padding-top: 72px;
}

.titulo {
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  color: #01295F;;
  margin-bottom: 12px;
}

.subtitulo {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #01295F;
  padding: 0% 6%
}

.logotipos {
  margin-bottom: 60px;
}

.logotipos div {
  border-radius: 60px;
  background: #FFFFFF;
  box-shadow: 8px 8px 36px rgba(30, 56, 136, 0.08);
  height: 88px;
  width: 280px;
  display: inline-flex;
  margin-right: 10px;
  margin-bottom: 10px;
}

.logotipos div:hover {
  background: linear-gradient(269.58deg, #0F5EF6 -16.47%, #232ED1 92.18%);
  box-shadow: 8px 8px 36px rgba(30, 56, 136, 0.08);
}

.logo {
  margin: auto;
}

.tarjetasPrecios {
  text-align: center;
}

.tarjetasPrecios-contenedor {
  max-width: 360px;
  display: inline-block;
  background: #FFFFFF;
  border: 1px solid #F0F0F0;
  border-radius: 8px;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 3% 2% 4% 2%;
  text-align: left;
}

.tarjetasPrecios-contenedor:hover {
  background: #232ED1;
  border: none;
  color: white;
}

.tarjetasPrecios-contenedor:hover>.listado-caracteristicas>div>.text-general,
.tarjetasPrecios-contenedor:hover>.listado-caracteristicas>.al-end>.text-paquete,
.tarjetasPrecios-contenedor:hover>.listado-caracteristicas>.al-end+div>.text-Nfacturas>.text-facturas,
.tarjetasPrecios-contenedor:hover>.listado-caracteristicas>.al-end+div>.text-Nfacturas {
  color: white;
}

.tarjetasPrecios-contenedor:hover>.listado-caracteristicas>.resaltado-precio {
  background-color: #3A44DC;
}

.tarjetasPrecios-contenedor:hover>.listado-caracteristicas>div>.boton-comprar {
  background-color: #232ED1;
  border: 1px solid #fff;
  color: #fff;
}

.tarjetasPrecios-contenedor:hover>.listado-caracteristicas>div>.boton-comprar>img {
  content: url("/public/images/images-precios/carWhite.svg");
}

.tarjetasPrecios-contenedor:hover>.listado-caracteristicas>div>img {
  content: url("/public/images/images-precios/checkWhite.svg");
}

.text-general {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #01295F;
}

.text-Nfacturas {
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  color: #01295F;
}

.text-facturas {
  font-weight: 400;
  font-size: 14px;
  top: -1em;
}

.text-paquete {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #232ED1;
}

.boton-comprar {
  border: 1px solid #232ED1;
  color: #232ED1;
  border-radius: 8px;
  padding: 2% 18%;
  background-color: #FFFFFF;
  display: grid;
  grid-template-columns: auto auto;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  align-items: center;
  justify-items: center;
  margin-top: 15px;
  width: 100%;
}

.boton-comprar img {
  display: grid;
  justify-self: right;
}

.boton-comprar:hover {
  border: 1px solid #0F5EF6;
  color: #0F5EF6;
}

/* Pruebas Tarjeta */

.listado-caracteristicas {
  display: grid;
  grid-template-rows: repeat(6, 1fr);
  gap: 10px;
  max-width: 312px;

}

.caracteristicas {
  display: grid;
  grid-template-columns: 8% 92%;
  gap: 3%;
  align-items: center;
  min-height: 44px;
}

.resaltado-precio {
  border-radius: 8px;
  background-color: #F4F7FF;
}

.al-end {
  align-self: flex-end;
}

.link-crear-cuenta h4 {
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 48px;
  color: #01295F;
}

.link-crear-cuenta a {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #0F5EF6;
  text-decoration: none;
}
.link-crear-cuenta a:hover {
  color: #232ED1;
}