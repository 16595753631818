.contenedor-contacto {
  margin-top: 100px;
  padding: 8% 0% 13% 0%;
  background: linear-gradient(
    to bottom,
    #f4f7ff 0%,
    #f4f7ff 50%,
    #fff 50%,
    #fff 100%
  );
}
.titulo-contacto-preguntas {
  font-weight: 600;
  font-size: 44px;
  line-height: 56px;
  color: #01295f;
  text-align: center;
  margin-bottom: 120px;
}

.contenedor-infoYform-contacto {
  display: grid;
  grid-template-columns: 40% 60%;
  padding: 0% 10%;
}

.contenedor-informacion-contacto {
  display: grid;
  grid-template-rows: repeat(10, auto);
  height: fit-content;
  padding-right: 10%;
  align-self: center;
  gap: 28px;
}

.subtitulo-contacto {
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  color: #01295f;
}

.input-form::placeholder,
.texto-informacion-contacto {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #515f72;
  text-decoration: none;
  width: fit-content;
}

.sub-subtitulo-contacto {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #01295f;
}

.two-colrow {
  display: grid;
  grid-template-columns: min-content auto;
  grid-template-rows: repeat(2, auto);
  gap: 10px;
}
.two-col-1 {
  display: grid;
  grid-template-columns: min-content auto;
  gap: 10px;
}
.two-col {
  display: grid;
  grid-template-columns: repeat(2, auto);
}

.five-col {
  display: grid;
  grid-template-columns: repeat(5, auto);
  gap: 10px;
  width: fit-content;
  height: fit-content;
}

.icon-red-social {
  width: 43px;
  height: 43px;
}

.icon-info {
  grid-row: 1 / -1;
  align-self: start;
  justify-self: center;
}

.formulario-contacto {
  background: #ffffff;
  box-shadow: 12px 12px 36px rgba(35, 46, 209, 0.16);
  border-radius: 12px;
  height: 920px;
  padding: 70px 5% 60px 8%;
}

.seven-row {
  display: grid;
  grid-template-rows: repeat(7, auto);
}

.gap-30 {
  gap: 30px;
}

.input-con-icon {
  position: relative;
}

.icon-input {
  position: absolute;
  top: 0;
  left: 0;
  padding: 16px;
}

.input-form {
  width: 100%;
  background: #fcfdff;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  outline: none;
  padding-left: 58px;
  padding-right: 15px;
  height: 58px;
}

.input-form::placeholder {
  color: #b6babd;
}

.text-area {
  max-width: 848px;
  height: 304px;
  resize: none;
  padding-top: 24px;
  padding: 24px 15px 15px 32px;
}

.boton-formulario-contacto {
  background: linear-gradient(86.42deg, #232ed1 11.64%, #0f5ef6 80.17%);
  box-shadow: 8px 8px 28px rgba(35, 46, 209, 0.16);
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  border: none;
  width: 188px;
  height: 52px;
  justify-self: end;
  position: relative;
  z-index: 1;
}

.boton-formulario-contacto::before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #232ed1;
  border-radius: 8px;
  z-index: -1;
  opacity: 0;
  transition: opacity 180ms ease-in-out;
}

.boton-formulario-contacto:hover::before {
  opacity: 1;
}

/* MEDIAS QUERY'S  */

@media screen and (max-width:1100px) {
  .two-col {
    grid-template-columns: auto;
    grid-template-rows: auto auto;
  }
  .formulario-contacto {
    height: auto;
  }
  .contenedor-infoYform-contacto{
    padding: 0% 5%;
  }
}

@media screen and (max-width:710px) {
  .contenedor-infoYform-contacto{
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    gap: 50px;
  }
  .formulario-contacto {
    padding: 70px 2% 60px 2%;
  }
}