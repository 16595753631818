.contenedor-comofunciona {
    background: #FFF;
}

.contenedor-introduccion {
    background-color: #F4F7FF;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    justify-items: center;
    padding: 11% 4% 0% 8%;
}

.contenedor-texto-introduccion {
    display: grid;
    grid-template-rows: repeat(3, auto);
    gap: 15px;
    padding-right: 5%;
}

.titulo-introduccion {
    font-weight: 600;
    font-size: 44px;
    line-height: 56px;
    color: #01295F;
    padding-top: 99px;
}

.subtitulo-introduccion {
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: #232ED1;
}

.texto-introduccion {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #01295F;
}

.image-email {
    width: 100%;
    max-width: 805px;
    max-height: 570px;
}

.image-wave {
    width: 100%;
    background: #F4F7FF;
}

.contenedor-texto-conexion {
    padding: 5% 10% 0% 10%;
}

.titulo-conexion {
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    color: #01295F;
    margin-bottom: 25px;
}

.subtitulo-conexion {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #515F72;
    margin-bottom: 25px;
}

.image-style {
    filter: drop-shadow(8px 8px 28px rgba(35, 46, 209, 0.16));
    border-radius: 8px;
    width: 100%;
    padding: 0% 15%;
    margin-bottom: 150px;
}

.contenedor-aprendizaje-parte1 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    margin-bottom: 100px;
    padding: 0% 8% 0% 10%;
    height: 410px;
}

.contenedor-aprendizaje-parte2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    margin-bottom: 100px;
    padding: 0% 10% 0% 8%;
    height: 410px;
}

.contenedor-aprendizaje-parte3 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    margin-bottom: 100px;
    padding: 0% 8% 0% 10%;
    height: 410px;
}

.contenedor-aprendizaje-parte4 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    margin-bottom: 100px;
    padding: 0% 10% 0% 8%;
    height: 410px;
}

.contenedor-texto-parte1 {
    display: grid;
    grid-template-rows: repeat(5, auto);
    height: fit-content;
    gap: 10px;
    padding-right: 15%;
}

.contenedor-texto-parte2 {
    display: grid;
    grid-template-rows: repeat(6, auto);
    height: fit-content;
    gap: 10px;
    padding-left: 18%;
}

.contenedor-texto-parte3 {
    display: grid;
    grid-template-rows: repeat(5, auto);
    height: fit-content;
    gap: 10px;
    padding-right: 15%;
}

.contenedor-texto-parte4 {
    display: grid;
    grid-template-rows: repeat(4, auto);
    height: fit-content;
    gap: 10px;
    padding-left: 18%;
}

.instruccion {
    display: grid;
    grid-template-columns: 8% 92%;
    align-items: start;
}

.instruccion img {
    justify-self: center;
}

.texto-aprendizaje {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #3FD77B;
}

.titulo-aprendizaje {
    font-weight: 600;
    font-size: 28px;
    line-height: 42px;
    color: #01295F;

}

.texto-contenido {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #515F72;
}

.images-aprendizaje {
    width: 100%;
    filter: drop-shadow(8px 8px 28px rgba(35, 46, 209, 0.18));
    border-radius: 8px;
    max-width: 760px;
    max-height: 408px;
}

.boton-aprendizaje {
    background: linear-gradient(86.42deg, #232ED1 11.64%, #0F5EF6 80.17%);
    box-shadow: 8px 8px 28px rgba(35, 46, 209, 0.16);
    border-radius: 8px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    border: none;
    width: 270px;
    height: 52px;
    margin-top: 20px;
}

.boton-aprendizaje:hover {
    background: #232ED1;
}

/* MEDIA QUERY'S */

@media screen and (min-width:1920px) {
    .imagen {
        justify-self: end;
    }
}

@media screen and (max-width:1380px) {
    .contenedor-aprendizaje-parte1 {
        height: auto;
    }

    .contenedor-aprendizaje-parte2 {
        height: auto;
    }

    .contenedor-aprendizaje-parte3 {
        height: auto;
    }

    .contenedor-aprendizaje-parte4 {
        height: auto;
    }
}

@media screen and (max-width:960px) {
    .contenedor-introduccion {
        grid-template-columns: auto;
        grid-template-rows: auto auto;
        gap: 30px;
        text-align: center !important;
    }

    .contenedor-aprendizaje-parte1 {
        justify-items: center;
        padding: 0% 3%;
    }

    .contenedor-aprendizaje-parte2 {
        justify-items: center;
        padding: 0% 3%;
    }

    .contenedor-aprendizaje-parte3 {
        justify-items: center;
        padding: 0% 3%;
    }

    .contenedor-aprendizaje-parte4 {
        justify-items: center;
        padding: 0% 3%;
    }
}

@media screen and (max-width:680px) {
    .contenedor-aprendizaje-parte1 {
        grid-template-columns: auto;
        grid-template-rows: auto auto;
        gap: 30px;
    }

    .contenedor-aprendizaje-parte2 {
        grid-template-columns: auto;
        grid-template-rows: auto auto;
        gap: 30px;
    }

    .contenedor-aprendizaje-parte3 {
        grid-template-columns: auto;
        grid-template-rows: auto auto;
        gap: 30px;
    }

    .contenedor-aprendizaje-parte4 {
        grid-template-columns: auto;
        grid-template-rows: auto auto;
        gap: 30px;
    }

    .contenedor-texto-parte1 {
        padding: 0;
    }

    .contenedor-texto-parte2 {
        padding: 0;
        grid-row: 1 / 2;
    }

    .contenedor-texto-parte3 {
        padding: 0;
    }

    .contenedor-texto-parte4 {
        padding: 0;
        grid-row: 1 / 2;
    }
}