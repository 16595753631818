.contenedor-aviso-privacidad {
  margin-top: 100px;
  padding-top: 120px;
  background: #F4F7FF;
}

.titulo-aviso-privacidad {
  text-align: center;
  font-weight: 600;
  font-size: 44px;
  line-height: 56px;
  color: #01295f;
  margin-bottom: 50px;
  padding: 0% 5%;
}

.contenedor-preambulo {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 0% 10%;
  margin-bottom: 50px;
  gap: 30px;
}

.contenedor-texto-preambulo {
  align-self: center;
}

.preambulo {
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  color: #01295f;
  margin-bottom: 20px;
}

.texto-preambulo {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #01295f;
}

.img-politicas {
  width: 100%;
  max-width: 600px;
  max-height: 500px;
  justify-self: end;
  align-self: center;
}

.listado-politicas {
  padding: 110px 12%;
}

.politica {
  display: grid;
  grid-template-columns: min-content auto;
  grid-template-rows: repeat(3,min-content);
}

.icon-checkbox {
  align-self: center;
  justify-self: center;
  margin-right: 15px;
}

.titulo-politica {
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  color: #01295f;
  margin-bottom: 0px;
}
.texto-politica {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #515f72;
  grid-column: 2 / -1;
  margin: 20px 0px 40px;
}

/* MEDIAS QUERY'S */

@media screen and (max-width:1000px) {
  .contenedor-preambulo {
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    gap: 80px;
  }
  .img-politicas {
    justify-self: center;
    align-self: center;
  }
}
@media screen and (max-width:600px) {
  .listado-politicas {
    padding: 110px 8%;
  }
}
@media screen and (max-width:400px) {
  .contenedor-preambulo {
    padding: 0% 6%;
  }
  .listado-politicas {
    padding: 110px 5%;
  }
}