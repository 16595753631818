.factuxin_logo {
    height: auto;
    width: auto;
}

.h-header {
    background: #FFF;
    height: 100px;
    padding: 0;
    transition: 200ms ease-in;
    box-shadow: 0px 4px 36px rgba(35, 46, 209, 0.12);
}

.container-fluid {
    height: 100%;
    padding-left: 8%;
    padding-right: 8%;
}


.boton-iniciar-sesion {
    background: #F4F7FF;
    border-radius: 8px;
    border: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #01295F;
    width: 205px;
    height: 52px;
    display: flex;
    align-items: center;
}

.boton-iniciar-sesion:hover {
    background: #E6ECFA;
}

.Icon {
    margin: 17.67px 13.33px 17.67px 39.33px;
}

.boton-crear-cuenta {
    background: linear-gradient(86.42deg, #232ED1 11.64%, #0F5EF6 80.17%);
    box-shadow: 8px 8px 28px rgba(35, 46, 209, 0.16);
    border-radius: 8px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    border: none;
    width: 175px;
    height: 52px;
}

.boton-crear-cuenta:hover {
    background: #232ED1;
}

.texto-navbar:focus,
.texto-navbar {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #01295F;
    margin-right: 20px;
}

.texto-navbar:hover {
    color: #232ED1;
}

.navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2835, 46, 209, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-toggler {
    border: 1px solid rgba(35, 46, 209, 1);
}

.navbar-toggler:focus {
    box-shadow: 0 0 0 0.25rem rgba(35, 46, 209, .8);
}

.contenedor-listado {
    display: grid;
    grid-template-columns: repeat(5, auto);
    align-items: center;
    justify-items: center;
    text-align: center;
    gap: 10px;
}

.dropdown-toggle::after {
    content: url(/public/images/images-header/dropdown.svg);
    border-top: 0;
}

/* NAVBAR HOME */

.estilo {
    background: rgba(255, 255, 255, 0.08);
    height: 100px;
    padding: 0;
    transition: 200ms ease-in;
}
.texto-navbar-home:focus,
.texto-navbar-home {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: black;
    margin-right: 20px;
}

.texto-navbar-home:hover {
    color: #92E3A9;
}

.icon-toggler-home {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.toggler-home {
    border: 1px solid rgba(255, 255, 255, 1);
}

.toggler-home:focus {
    box-shadow: 0 0 0 0.25rem rgba(212, 214, 234, 0.8);
}

@media screen and (min-width:768px){
    .texto-navbar-home:focus, .texto-navbar-home{
        color: white;
    }
}
@media screen and (max-width:1199px) {
    .contenedor-listado {
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: repeat(5, auto);
        align-items: center;
        justify-items: center;
        gap: 10px;
    }
    .texto-navbar-home,
    .texto-navbar{
        margin-right: 0;
    }
}