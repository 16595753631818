.background-registro {
  background-image: url(/public/images/images-registro/Background.jpg);
  background-size: 46.25% 100%;
  background-position: initial;
  background-repeat: no-repeat;
  background-color: #fff;
  background-attachment: fixed;
  height: 100%;
}

.contenido-registro {
  display: grid;
  grid-template-columns: 46.25% 53.75%;
  gap: 40px 0px;
}

.contenedor-prueba {
  text-align: center;
  margin: 37% 21% 0px 17%;
}

.titulo-prueba {
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  color: #fff;
  margin-bottom: 16px;
}

.texto-prueba {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #fff;
  margin-bottom: 16px;
}

.link {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #0f5ef6;
  text-decoration: none;
}

.link:hover {
  color: #232ed1;
}

.formulario-registro {
  background: #ffffff;
  box-shadow: 8px 8px 48px rgba(30, 56, 136, 0.2);
  border-radius: 8px;
  /* padding: 6% 3.5% 4.2%; */
  padding: 96.9px 43px 32px 36px;
  margin: 7.2% 8.2% 0px 9.5%;
}

.titulo-form {
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  color: #01295f;
  margin-bottom: 6.5px;
}

.subtitulo-form {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #01295f;
  margin-bottom: 27px;
  padding: 0% 2%;
}

.inputs-form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(5, auto);
  gap: 25px;
}

.labels {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #01295f;
  margin-bottom: 22px;
}

.input-icon-form {
  position: relative;
}

.icon-registro {
  position: absolute;
  top: 0;
  left: 0;
  padding: 15.5px 0px;
  padding-left: 12px;
}

.input-icon-form input {
  width: 100%;
  height: 49px;
  background: #f4f7ff;
  padding-left: 46px;
  border: 3px solid transparent;
  outline: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #000;
  transition: border 150ms ease-in-out;
}

.border-error {
  border: 3px solid red !important;
}

.input-icon-form input::placeholder {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #c3c5d2;
}

.boton-registro {
  background: linear-gradient(86.42deg, #232ed1 11.64%, #0f5ef6 80.17%);
  box-shadow: 8px 8px 28px rgba(35, 46, 209, 0.16);
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  height: 49px;
  border: none;
}

.boton-registro:hover {
  background: #232ed1;
}

.form-footer {
  display: grid;
  grid-template-columns: 70% 30%;
  width: 100%;
  height: fit-content;
}

.align {
  justify-self: end;
}

.logos-empresas {
  display: grid;
  grid-template-columns: repeat(4, auto);
  padding: 0px 4%;
  align-items: center;
  justify-items: center;
  align-self: end;
}

.footer-registro {
  display: grid;
  grid-template-columns: repeat(3, auto);
  align-items: center;
  justify-items: center;
  padding: 0px 5%;
  margin-bottom: 16px;
}

.texto-derechos,
.texto-footer-registro {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  text-decoration: none;
}

.texto-derechos {
  color: #01295f;
  justify-self: center;
}

.texto-footer-registro:hover {
  color: #3fd77b;
}

.recaptcha {
  width: 258px;
  height: 64px;
  transform: scale(0.848, 0.82);
  transform-origin: 0 0;
  display: grid;
}

.contenedor-boton-terminos {
  display: grid;
  grid-template-rows: repeat(2, auto);
  align-items: end;
}

input[type="checkbox"] {
  margin-right: 8px;
  transform: scale(1.2);
}

.bold {
  font-weight: 600;
}

.contenedor-texto-logos {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
}

.tengo-cuenta {
  margin-bottom: 0px;
  align-self: center;
}

.terminos {
  margin-bottom: 0px;
  font-size: 14px;
  line-height: 17px;
  align-self: center;
}

.planes {
  color: #3fd77b;
}
.planes:hover {
  color: #fff;
}

.img-responsive {
  display: none;
}
.error {
  display: none;
  color: red;
  font-weight: 400;
  font-size: 16px;
}

/* MEDIAS QUERY'S */

@media screen and (max-width: 1510px) {
  .formulario-registro {
    margin: 7.2% 3.2% 0px 4.5%;
  }
}
@media screen and (max-width: 1380px) {
  .logos-empresas {
    grid-template-columns: repeat(2, 160px);
    grid-template-rows: repeat(2, 38px);
    padding: 0px;
    justify-self: center;
    gap: 25px;
  }
  .inputs-form {
    grid-template-columns: auto;
  }
  .background-registro {
    background-attachment: fixed;
    height: 100%;
  }
  .contenedor-prueba {
    margin: 20% 15% 0px 11%;
  }
  .contenedor-boton-terminos {
    gap: 25px;
    text-align: center;
  }
  input[type="checkbox"] {
    margin-right: 15px;
    transform: scale(1.5);
  }
  .tengo-cuenta {
    justify-self: center;
  }
  .recaptcha {
    justify-self: center;
  }
  .footer-registro {
    padding: 0px 1%;
  }
}
@media screen and (max-width: 1125px) {
  .footer-registro {
    grid-template-columns: auto;
    grid-template-rows: repeat(3, auto);
    gap: 15px;
  }
  .contenedor-prueba {
    margin: 20% 11% 0px 7%;
  }
}
@media screen and (max-width: 1000px) {
  .background-registro {
    background-image: none;
  }
  .img-responsive {
    display: block;
    width: 100%;
  }
  .contenido-registro {
    grid-template-columns: auto;
    gap: 0px;
  }
  .contenedor-prueba {
    margin: 3% 8% 0%;
  }
  .titulo-prueba {
    color: #0f5ef6;
  }
  .texto-prueba {
    color: #01295f;
  }
  #letras {
    fill: #000;
  }
  .contenedor-texto-logos {
    grid-template-rows: repeat(2, auto);
    gap: 40px;
  }
  .planes {
    color: #0f5ef6;
  }
  .planes:hover {
    color: #232ed1;
  }
  .formulario-registro{
    margin-top: 40px;
  }
  .texto-derechos,
  .texto-footer-registro{
    color: #2D3277;
  }
  .texto-footer-registro:hover{
    color: #3fd77b;
  }
  .footer-registro{
    margin-top: 25px;
  }
}
@media screen and (max-width:500px) {
  .formulario-registro{
    margin: 0px;
    border-radius: none;
    box-shadow: none;
    padding: 40px 4% 0%;
  }
}
