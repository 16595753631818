.contenedor-terminos {
    padding-top: 90px;
    background: #F4F7FF
}
.contenedor-intro-terminos {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    padding: 40px 10% 50px;
    gap: 30px;
}

.contenedor-texto-politicas {
    align-self: center;
}

.img-terminos {
    width: 100%;
    max-width: 600px;
    max-height: 500px;
    justify-self: end;
    align-self: center;
}

/* MEDIAS QUERY'S */

@media screen and (max-width:1000px) {
    .contenedor-intro-terminos {
      grid-template-columns: auto;
      grid-template-rows: auto auto;
      gap: 80px;
    }
    .img-terminos {
      justify-self: center;
      align-self: center;
    }
}
@media screen and (max-width:400px) {
    .contenedor-intro-terminos {
        padding: 40px 6% 50px;
      }
}