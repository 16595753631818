.contenedor-home {
  background-color: #FFF;
}

.contenedor-presentacion {
  background-image: url(/public/images/images-home/background.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #051C3F;
  width: 100%;
  position: relative;
}

.img-background {
  width: 100%;
  height: 100%;
}

.olas {
  width: 100%;
}

.curva {
  width: 100%;
  background: #FFF;
}

.contenido-presentacion {
  display: grid;
  grid-template-columns: repeat(2, minmax(280px, 1fr));
  padding: 10% 4.3% 0% 7%;

}

.texto-presentacion {
  display: grid;
  grid-template-rows: repeat(3, auto) minmax(52px, max-content);
  top: 0;
  width: 100%;
  height: fit-content;
  gap: 15px;
  justify-self: center;
  align-self: center;
}

.titulo-presentacion {
  color: #fff;
  font-weight: 800;
  font-size: 48px;
  line-height: 56px;
  text-align: left;
  padding-top: 99px;
}

.subtitulo-presentacion {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #232ED1;
  text-align: left;
}

.parrafo-presentacion {
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
}

.boton-presentacion {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #FFF;
  background: linear-gradient(86.42deg, #232ED1 11.64%, #0F5EF6 80.17%);
  box-shadow: 8px 8px 28px rgba(35, 46, 209, 0.16);
  border-radius: 8px;
  border: none;
  max-width: 265px;
  height: 52px;
}

.boton-paraquien {
  width: 180px;
  margin-top: 20px;
}

.boton-paraquien:hover,
.boton-presentacion:hover {
  background: #232ED1;
}

.img-ecommerce {
  width: 100%;
  height: 100%;
}

.imagen-presentacion {
  align-self: center;
  justify-self: center;
}

.contenedor-encabezado {
  padding: 0% 9%;
}

.txt-titulo {
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  color: #01295F;
  text-align: center;
  margin-bottom: 20px;
}

.txt-subtitulo {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  color: #515F72;
  margin-bottom: 30px;
}

.imagenes-muestra {
  width: 100%;
  padding: 0% 5%;
}


.hola {
  background-image: url(/public/images/images-home/rectangulo-back.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 4%;
  background-color: #F4F7FF;
}

.contenedor-tarjetasyparaquien {
  display: grid;
  grid-template-columns: repeat(2, auto);
  padding: 5% 8% 0% 8%;
}

.paraquienes {
  background-image: url(/public/images/images-home/difuminado.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: grid;
  grid-template-rows: repeat(7, auto);
  height: fit-content;
  align-self: center;
  gap: 10px;
  padding-right: 10%;
}

.listado {
  display: grid;
  grid-template-columns: 8% 92%;
  align-items: start;
  justify-items: center;
}

.tema-listado {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #3FD77B;
}

.titulo-listado {
  font-weight: 700;
  font-size: 28px;
  line-height: 56px;
  color: #01295F;
}

.texto-listado {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #081B33;
  justify-self: left;
}

.contenedor-tarjetas {
  display: grid;
  grid-template-columns: repeat(2, 320px);
  grid-template-rows: repeat(2, 425px);
  background-image: url(/public/images/images-home/circle.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  justify-content: center;
  gap: 20px;
}

.tarjeta {
  background: #FFFFFF;
  box-shadow: 8px 8px 28px rgba(35, 46, 209, 0.16);
  border-radius: 8px;
  padding: 5% 5% 8% 5%;
}

.tarjeta-r {
  background: linear-gradient(0deg, #232ED1 0%, #0F5EF6 100%);
  box-shadow: 8px 8px 28px rgba(35, 46, 209, 0.16);
  border-radius: 8px;
  color: white;
  padding: 6% 6% 0% 6%;
}

.titulo-tarjeta {
  font-weight: 700;
  font-size: 16px;
  line-height: 56px;
  color: #232ED1;
}

.texto-tarjeta {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #515F72;
}

.home-parte3 {
  text-align: center;
  padding: 180px 16% 2%;
}

.titulo-parte3 {
  font-weight: 700;
  font-size: 28px;
  line-height: 48px;
  color: #01295F;
  margin-bottom: 15px;
}

.subtitulo-parte3 {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #515F72;
}

.img-dashboard {
  width: 100%;
  filter: drop-shadow(8px 8px 28px rgba(35, 46, 209, 0.16));
  border-radius: 8px;
  padding: 0% 12%;
}

.home-parte4 {
  display: grid;
  grid-template-columns: repeat(2, auto);
  padding: 0% 8%;
  margin: 125px 0;
}

.contenedor-cartas {
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 35px;
}

.cart {
  box-shadow: 8px 8px 28px rgba(35, 46, 209, 0.16);
  border-radius: 8px;
}

.contenedor-texparte4-home {
  display: grid;
  grid-template-rows: repeat(3, auto);
  align-self: center;
  gap: 16px;
  padding-left: 16%;
}

.titulo-texto-part4 {
  line-height: 42px;
}

.img-background2 {
  padding: 0% 8%;
}

.home-parte5 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-image: url(/public/images/images-home/Background2.svg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 568px;
  margin-bottom: 150px;
}

.img-invoice {
  padding: 0% 1%;
  width: 100%;
  align-self: center;
  justify-self: center;
  max-width: 535px;
  max-height: 506px;
}

.contenedor-cotizaciones {
  display: grid;
  grid-template-rows: repeat(4, auto);
  height: fit-content;
  align-self: center;
  padding-right: 2%;
}

.titulo-cotizaciones {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #3FD77B;
  margin-bottom: 5px;
}

.subtitulo-cotizaciones {
  font-weight: 600;
  font-size: 28px;
  line-height: 44px;
  color: #FFF;
  margin-bottom: 20px;
}

.texto-cotizaciones {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #E0E0E0;
  margin-bottom: 20px;
}

.boton-prueba {
  background: #3FD77B;
  box-shadow: 8px 8px 28px rgba(35, 46, 209, 0.16);
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
  width: 264px;
  height: 52px;
  border: none;
}

.boton-prueba:hover {
  background: #20C05F;
}

.contenedor-texto-homeParte6 {
  margin-bottom: 25px;
  text-align: center;
  padding: 0% 20%;
}

.home-parte6 {
  background: linear-gradient(to top, #F4F7FF 0%, #F4F7FF 50%, #fff 50%, #fff 100%);
}

.titulo-parte6 {
  font-weight: 700;
  font-size: 28px;
  line-height: 48px;
  color: #01295F;
}

.subtitulo-parte6 {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #515F72;
}

.contenedor-tarjetas-beneficios {
  display: grid;
  grid-template-columns: repeat(4, auto);
  gap: 25px;
  width: fit-content;
  justify-items: center;
  margin: auto;
  padding-bottom: 150px;
}

.tarjeta-beneficio {
  width: 316px;
  height: 425px;
  background: #FFFFFF;
  box-shadow: 8px 8px 28px rgba(35, 46, 209, 0.16);
  border-radius: 8px;
  text-align: center;
  padding: 0% 27px;
}

.tarjeta-beneficio img {
  padding-top: 48px;
  padding-bottom: 34px;
}

.titulo-tarjeta-beneficio {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #232ED1;
  margin-bottom: 10px;
  text-align: left;
}

.texto-tarjeta-beneficio {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #515F72;
  text-align: left;
}

/* MEDIA QUERY'S */

@media screen and (max-width: 1360px) {
  .contenedor-tarjetas-beneficios {
    grid-template-columns: repeat(2, auto);
    grid-template-rows: repeat(2, auto);
  }
}

@media screen and (max-width:1230px) {
  .contenedor-tarjetasyparaquien {
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    gap: 50px;
  }

  .paraquienes {
    padding: 2%;
  }

  .home-parte4 {
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    gap: 50px;
  }

  .contenedor-cartas {
    justify-self: center;
    width: fit-content;
    grid-row: 2 / -1;
  }

  .contenedor-texparte4-home {
    padding: 0;
    grid-row: 1 / 2;
    text-align: center;
  }

  .home-parte5 {
    height: auto;
  }

  .contenedor-cotizaciones {
    padding: 4% 2%;
  }

  .contenedor-texto-homeParte6 {
    padding: 0% 8%;
  }

  .home-parte3 {
    padding: 180px 8% 2%;
  }
}

@media screen and (max-width:780px) {
  .contenido-presentacion {
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    gap: 50px;
  }

  .contenedor-tarjetas {
    grid-template-columns: 310px;
    grid-template-rows: repeat(4, 425px);
    justify-content: center;
    gap: 30px;
  }

  .contenedor-cartas {
    grid-template-columns: auto;
    grid-template-rows: auto auto;
  }

  .home-parte5 {
    grid-template-columns: auto;
    grid-template-rows: repeat(2, auto);
    gap: 50px;
  }

  .img-background2 {
    padding: 0% 4%;
  }

  .img-invoice {
    grid-row: 2 / -1;
    padding-bottom: 4%;
  }

  .contenedor-cotizaciones {
    grid-row: 1 / 2;
    padding-bottom: 0;
  }

  .contenedor-tarjetas-beneficios {
    grid-template-columns: auto;
    grid-template-rows: repeat(4, auto);
  }

  .img-dashboard {
    padding: 0% 4%;
  }
}

@media screen and (max-width:380px) {
  .contenedor-tarjetasyparaquien {
    padding: 0%;
    margin: auto;
  }

  .home-parte4 {
    padding: 125px 0px;
    margin: auto;
  }

  .contenedor-texparte4-home {
    padding: 2%;
  }
}